<template>
	<div class="notice">
		<div class="header">
			<div>裁判员管理></div>
			<div class="current">裁判资讯</div>
		</div>
		<div class="horizontalline" v-if="date && date.length > 0"></div>

		<div class="content">
			<div class="specific">
				<ul>
					<li v-for="(item,index) in date" :key="index">
						<div class="rounddot">
							<div class="round"></div>
							<a href="" class="roundtitle" @click="jump(item.id)">
								{{item.title}}
							</a>
						</div>
						<span>[{{item.publishTime | format}}]</span>
					</li>
				</ul>
			</div>
			<div class="horizontalline" v-if="date && date.length > 0"></div>
		</div>
		<div class="paging">
			<pagination :total="total" @currentchange="currentchange" :go="10" :pageSize="10" :pageNo="pageNum"></pagination>
		</div>
		
	</div>
</template>
<script>
	export default {
		name: 'registrationinformation',
		components: {},
		data() {
			return {
				id:718,
				pageNum:1,
				pageSize:10,
				total:0,
				date:[]
			}
		},
		filters: {
			format(x) {
				if(x != null && x != ''){
					return x.split(" ")[0]
				}
			}
		},
		created() {
			this.getArticleListById()
		},
		methods: {
			currentchange(val){
				this.pageNum = val
				this.getArticleListById()
			},
			getArticleListById(){
				let data = {
					id:this.$route.query.id,
					pageNum: this.pageNum,
					pageSize: this.pageSize,
				}
				this.$api.getArticleListById(data).then(res=>{
					this.total = res.data.data.total
					this.date = res.data.data.list
				})
			},
			jump(id) {
				let routeData = this.$router.resolve({
					path: '/articleDetails',
					query: {
						id: id,
						refresh: new Date().getTime()
					}
				});
				window.open(routeData.href, '_blank');
			}
		}
	}
</script>
<style  lang="scss" scoped>
	.notice {
		width: 1200PX;
		margin: 0 auto;
	}

	.header {
		display: flex;
		margin-top: 34PX;
		margin-left: 63PX;
		font-size: 18PX;
		font-weight: 500;
		color: #333333;
		margin-bottom: 23PX;
		cursor: pointer;
	}
	
	.current {
		color: #0066FF;
	}
	
	.content {
		width: 1200PX;
		margin: 0 auto;
	}
	
	.horizontalline {
		border: 1PX dashed #BFBFBF;
		margin-bottom: 23PX;
		margin-left: 53PX;
	}

	.specific {
		margin-left: 53PX;
		font-size: 15PX;
		color: #333333;
	}

	.specific ul li {
		display: flex;
		justify-content: space-between;
		margin-bottom: 23PX;
	}

	.rounddot {
		display: flex;
		align-items: center
	}

	.round {
		width: 7PX;
		height: 7PX;
		border-radius: 100%;
		background: #000;
		box-shadow: 0 2PX 4PX 0 rgba(0, 0, 0, .2);
		margin-right: 10PX;
	}
	.roundtitle {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		-o-text-overflow: ellipsis;
		width: 1000PX;
	}

	.paging {
		margin-top: 81PX;
		margin-bottom: 132PX;
		display: flex;
		margin-left: 53PX;
		align-items: center;
	}

	.paging span {
		margin-right: 5PX;
	}
	
	::v-deep .el-pager li.active{
		color: #003399;
	}
</style>